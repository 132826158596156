import { APP_INITIALIZER, Provider } from '@angular/core';
import { CurrentUserController } from '@app/current-user/controllers/current-user.controller';

export const loadCurrentUserProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (usersController: CurrentUserController) => async () => {
    try {
      await usersController.fetchCurrentUser();
    } catch (e) {
      console.error(e);
    }
  },
  deps: [CurrentUserController],
};
