import { NotifierServicePort } from '../../shared/domain/ports/notifier.service.port';

export class BrowserNotifierServiceAdapter implements NotifierServicePort {
  success(message: string, timeout = 5000) {
    this.notify(message, { timeout, type: 'success' });
  }

  error(message: string, timeout = 5000) {
    this.notify(message, { timeout, type: 'error' });
  }

  info(message: string, timeout = 5000) {
    this.notify(message, { timeout, type: 'info' });
  }

  private notify(message: string, options?: { type: 'error' | 'info' | 'success'; timeout: number }) {
    console.log(message, options);
  }
}
