import { Routes } from '@angular/router';

import currentUserRoutes from './current-user/current-user.routes';
import { ifSignedIn } from './current-user/guards/signed-in.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: () => {
      return 'auth/sign-in';
    },
  },
  {
    path: 'drills',
    loadChildren: () => import('@app/drills/drills.routes'),
    canActivateChild: [ifSignedIn],
    title: 'Drills',
  },
  ...currentUserRoutes,
  {
    path: '**',
    redirectTo: '',
  },
];
