import { Injectable } from '@angular/core';
import { TRPCClientError } from '@trpc/client';
import { GenericException } from '../../shared/domain/entities/exceptions';
import { TRPCClient as client } from '../../trpc-client';
import {
  SecurityChallenge,
  SecurityChallengeChannel,
  SecurityChallengeException,
  SecurityChallengeScope,
} from '../domain/entities/security-challenge.entity';
import { SecurityChallengesRepositoryPort } from '../domain/ports/security-challenges.repository.port';

interface ChallengeDto {
  challengeId: string;
  type: 'one-time-password';
  scope: 'reset_password' | 'verify_email';
  channel: 'email';
  channelIdentifier: string;
  expiresAt: Date;
  canResentAt: Date;
}

@Injectable()
export class TRPCSecurityChallengesRepositoryAdapter implements SecurityChallengesRepositoryPort {
  async takeChallenge(
    scope: SecurityChallengeScope,
    channel: SecurityChallengeChannel,
    channelIdentifier: string,
  ): Promise<SecurityChallenge> {
    const dto = await client.user.takeSecurityChallengeWhileUnauthenticated.mutate({
      scope,
      channel,
      channelIdentifier,
    });

    if (!dto) {
      throw new SecurityChallengeException('not-found');
    }

    return new SecurityChallenge(
      dto.challengeId,
      dto.type,
      dto.scope,
      dto.channel,
      dto.channelIdentifier,
      new Date(dto.expiresAt),
      new Date(dto.canResendAt),
    );
  }

  async submitChallenge(
    challengeId: SecurityChallenge['id'],
    type: 'one-time-password',
    challengeSubmittal: string,
  ): Promise<{ grant: 'reset_password' | null }> {
    try {
      const trpcResponse = await client.user.submitSecurityChallenge.mutate({
        challengeId,
        type,
        challengeSubmittal,
      });

      return trpcResponse;
    } catch (e) {
      if (e instanceof TRPCClientError) {
        switch (e.data.code) {
          case 'NOT_FOUND':
            throw new SecurityChallengeException('not-found');
          case 'UNAUTHORIZED':
            throw new SecurityChallengeException('invalid-code');
        }
      }

      throw new GenericException('unknown-error', e);
    }
  }
}
