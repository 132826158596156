import { isPlatformBrowser } from '@angular/common';
import { Component, DestroyRef, PLATFORM_ID, effect, inject, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationSkipped,
  NavigationStart,
  Router,
} from '@angular/router';
import { NgProgressComponent, NgProgressModule } from 'ngx-progressbar';
import { filter, map, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [NgProgressModule],
  selector: 'ls-progress-bar',
  template: `@defer (when isBrowser) {
    <ng-progress aria-label="Page load progress"></ng-progress>
  }`,
})
export class ProgressBarComponent {
  private readonly destroyRef = inject(DestroyRef);
  progress = viewChild(NgProgressComponent);
  isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  private readonly router = inject(Router);

  constructor() {
    effect(() => {
      const progress = this.progress();
      if (progress) {
        this.listenToRouterEvents(progress);
      }
    });
  }

  private listenToRouterEvents(progress: NgProgressComponent) {
    this.router.events
      .pipe(
        filter((event): event is NavigationStart => event instanceof NavigationStart),
        map(() =>
          setTimeout(() => {
            progress.start();
          }, 30),
        ),
        switchMap((timeoutId) => {
          return this.router.events.pipe(
            filter((event) => {
              return (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationSkipped ||
                event instanceof NavigationError
              );
            }),
            take(1),
            map(() => timeoutId),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((timeoutId) => {
        clearTimeout(timeoutId);
        progress.complete();
      });
  }
}
