import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { NotifierServicePort } from './domain/ports/notifier.service.port';
import { BrowserNotifierServiceAdapter } from './adapters/browser.notifier.service.adapter';

export function provideSharedDomain(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: NotifierServicePort,
      useClass: BrowserNotifierServiceAdapter,
    },
  ]);
}
