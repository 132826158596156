import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { RedirectionsService } from '@app/shared/services/redirections.service';
import { CurrentUserController } from '../controllers/current-user.controller';

export const ifSignedIn: CanActivateFn = (route) => {
  const ctrl = inject(CurrentUserController);
  const redirections = inject(RedirectionsService);

  if (ctrl.getCurrentUser()) {
    return true;
  } else {
    return redirections.signInUrlTree;
  }
};

export const ifNotSignedIn: CanActivateFn = () => {
  const ctrl = inject(CurrentUserController);
  const redirections = inject(RedirectionsService);

  console.log(ctrl.getCurrentUser());
  if (ctrl.getCurrentUser()) {
    return redirections.afterSignInUrlTree;
  } else {
    return true;
  }
};
