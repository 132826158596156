import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { CurrentUserController } from '@app/current-user/controllers/current-user.controller';
import { RedirectionsService } from '@app/shared/services/redirections.service';
import { SecurityChallengeChannel, SecurityChallengeScope } from '../../domain/entities/security-challenge.entity';
import { CurrentUserVm } from '../../domain/view-models/current-user.vm';
import { SecurityChallengeVm } from '../../domain/view-models/security-challenge.vm';

export interface SecurityChallengeResolverData {
  challengeScope: SecurityChallengeScope;
}

function getSecurityChallengeScope(route: ActivatedRouteSnapshot): SecurityChallengeScope {
  const scope = route.queryParamMap.get('scope') as unknown;

  if (scope === 'verify_email' || scope === 'reset_password') {
    return scope;
  } else {
    throw new Error('No scope provided');
  }
}

function getChannelIdentifier(route: ActivatedRouteSnapshot, user: CurrentUserVm | null): string {
  if (user) {
    return user.email;
  } else if (route.queryParamMap.has('identifier')) {
    return route.queryParamMap.get('identifier')!;
  } else {
    throw new Error('Invalid channel identifier');
  }
}

export const securityChallengeResolver: ResolveFn<SecurityChallengeVm | null> = async (
  route: ActivatedRouteSnapshot,
): Promise<SecurityChallengeVm | null> => {
  const ctrl = inject(CurrentUserController);
  const router = inject(Router);
  const redirections = inject(RedirectionsService);

  const user = ctrl.getCurrentUser();
  const channel: SecurityChallengeChannel = 'email';

  try {
    const scope = getSecurityChallengeScope(route);
    const channelIdentifier = getChannelIdentifier(route, user);
    return ctrl.takeChallenge(scope, channel, channelIdentifier);
  } catch {
    router.navigateByUrl(redirections.defaultAuthenticatedUrlTree);
    return null;
  }
};
