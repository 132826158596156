import { ApplicationConfig, inject, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import provideRootVerbs from './root.providers';

import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpRequest,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';
import { catchError, from, switchMap, tap, throwError } from 'rxjs';
import { routes } from './app.routes';
import { CurrentUserController } from './current-user/controllers/current-user.controller';
import { loadCurrentUserProvider } from './current-user/initializers/load-current-user.initializer';
import { INTERNAL_BACKEND_URL } from './shared/di/internal-backend-url.di';
import { REQUEST_COOKIES } from './shared/di/request-cookies.di';
import { sentryProviders } from './shared/di/sentry.providers';
import { provideSharedDomain } from './shared/shared.config';

const authInterceptor: HttpInterceptorFn = (req, next) => {
  const cookies = inject(REQUEST_COOKIES, { optional: true });
  const internalBackendUrl = inject(INTERNAL_BACKEND_URL, { optional: true });
  const router = inject(Router);
  const currentUserCtrl = inject(CurrentUserController);

  let newRequest: HttpRequest<any> = req.clone({
    withCredentials: true,
  });

  if (cookies) {
    newRequest = newRequest.clone({
      setHeaders: {
        Cookie: cookies,
      },
    });
  }

  if (internalBackendUrl) {
    newRequest = newRequest.clone({
      url: `${internalBackendUrl}/${req.url}`,
    });
  }

  return next(newRequest).pipe(
    catchError((event: HttpErrorResponse) => {
      if (event instanceof HttpErrorResponse && event.status === 401) {
        return from(currentUserCtrl.signOut()).pipe(
          tap(() => {
            router.navigate(['/', 'auth', 'sign-in']);
          }),
          switchMap(() => throwError(() => event)),
        );
      } else {
        return throwError(() => event);
      }
    }),
  );
};

export const appConfig: ApplicationConfig = {
  providers: [
    sentryProviders,
    loadCurrentUserProvider,
    provideZoneChangeDetection({ eventCoalescing: true }),
    isDevMode() ? [] : provideClientHydration(withEventReplay()),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    provideRouter(routes, withComponentInputBinding()),
    provideRootVerbs(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideSharedDomain(),
  ],
};
